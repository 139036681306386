
































export default
  name: 'archive'
  mounted: ->
    @projects[0].participation.success = @$store.getters.countPaid
  data: ->
    projects: [
      {
        title: 'Ремонт въездных ворот (август 2024)'
        description: 'ТО, замена двигателя и роликов'
        budget: 228000
        budget_details: ''
        participation:
          total: 152
          success: 1
        period: 'август 2024'
        receipts: []
      }
      {
        title: 'Ямочный ремонт дороги КПП-Кафе 2024'
        description: 'ямочный ремонт дороги от остановки до ворот'
        budget: 608000
        budget_details: ''
        participation:
          total: 152
          success: 64
        period: 'июль 2024'
        receipts: []
      }
      {
        title: 'Уборка снега декабрь 2023'
        description: 'сбор на уборку снега'
        budget: 456000
        budget_details: ''
        participation:
          total: 152
          success: 93
        period: 'декабрь 2023'
        receipts: []
      }
      {
        title: 'Ямочный ремонт дороги КПП-Кафе 2023'
        description: 'ямочный ремонт дороги от остановки до ворот'
        budget: 76000
        budget_details: ''
        participation:
          total: 152
          success: 95
        period: '04.06.2023'
        receipts: []
      }
      {
        title: 'Уборка территории апрель 2023'
        description: 'сбор на уборку территории'
        budget: 76000
        budget_details: ''
        participation:
          total: 152
          success: 89
        period: '10-30.03.2023'
        receipts: []
      }
      {
        title: 'Уборка снега февраль 2023'
        description: 'сбор на уборку снега'
        budget: 81000
        budget_details: ''
        participation:
          total: 152
          success: 81
        period: '01-31.03.2023'
        receipts: []
      }
      {
        title: 'Ремонт насоса и замена двигателя 2023'
        description: 'Замена движка после перебоев с электричеством'
        budget: 71200
        budget_details: 'двигатель 36565 + подъем/опускание 12000 + ремонт 20000 + доставка 2630'
        participation:
          total: 17
          success: 16
        period: '08-10.01.2023'
        receipts: ['receipt_engine-2023-01.jpg']
      }
      {
        title: 'Уборка снега'
        description: 'Изначально собирали на подсыпку дорог, затем сменили цель на уборку снега'
        budget: 152000
        budget_details: 'подсыпка отменилась, дальше собираем в фонд уборки снега'
        participation:
          total: 152
          success: 83
        period: '22-30.12.2022'
        receipts: []
      }
      {
        title: 'Замена насоса'
        description: '<p><strong>Полная замена насоса.</strong></p><p>Теперь у нас новый насос с плавным пуском. Очень надеемся, что этого хватит надолго.</p>'
        budget: 109733
        budget_details: '69400₽ — насос и термомуфта, 30000₽ работы по подъему/опусканию и замене. 1000₽ — узо-автомат, 9333₽ — реле давления, остаток 7000₽ — передано в фонд дорог.'
        participation:
          total: 16
          success: 16
        period: '19-21.12.2021'
        receipts: ['water-pump.png', 'water-pump-2.png', 'water-pump-3.jpg']
      }
      {
        title: 'Плавный пуск'
        description: '<p><strong>Замена блока питания и вынос электроники наружу.</strong></p><p>Блок управления добавит плавный пуск/остановку при включениях насоса, что защитит кабель от скручивания и перетирания о стены скважины, как уже было трижды. Вынос на поверхность защитит электронику от возможного затопления кессона, как это было на 2-й линии регулярно.</p>'
        budget: 52800
        budget_details: '30000₽ — покупка блока управления и предоплата за оставшиеся комплектующие, 2800 — погашение остатка по предыдущему ремонту. 20000₽ — оплата работы.'
        participation:
          total: 16
          success: 16
        period: '01-10.10.2021'
        receipts: ['block.jpg']
      }
      {
        title: 'Вода-вода, где вода'
        description: 'Экстренный ремонт скважины'
        budget: 46250
        budget_details: 'Подъем/опускание насоса, ремонт кабеля, настройка гидроаккумуляторов.'
        participation:
          total: 17
          success: 16
        period: '03.07.2021'
        receipts: ['water-2-0.png', 'water-2-1.png']
      }
      {
        title: 'Чистота и порядок'
        description: 'Уборка территории от ворот поселка до автобусной остановки'
        budget: 45000
        budget_details: 'Мешки и перчатки, работы по уборке, контейнер для мусора, ремонт ливневки на общей дороге, взнос на ремонт дороги от кафе до поселка.'
        participation:
          total: 152
          success: 90
        period: '14-17.04.2021, июнь 2021'
        receipts: ['bags.jpg', 'container.jpg', 'cleaning.jpg', 'to_podosinki.jpg', 'for_road.png', 'for_road2.png']
      }
      {
        title: 'Вода и воздух'
        description: 'Ремонт скважины на 3-й линии.'
        budget: 28000
        budget_details: 'Замена обратного клапана и термомуфты, техническое обслуживание насоса.'
        participation:
          total: 18
          success: 18
        period: '1-6.04.2021'
        receipts: ['water.jpg']
      }
    ]

